import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase.config";

const gameStateCollection = collection(db, "gameState");
const levelsCollection = collection(db, "levels");
const challengesCollection = collection(db, "challenges");

export const getGameState = (id: string, cb: any) => {
  const docRef = doc(gameStateCollection, id);
  return onSnapshot(
    docRef,
    (snapshot) => {
      if (!snapshot.exists) return cb({}, null);
      return cb({ ...snapshot.data() }, null);
    },
    (err) => {
      console.log(err);
      throw new Error(err?.message);
    }
  );
};

export const getGameLevelsResults = async (uid: string) => {
  const ref = doc(gameStateCollection, uid);
  const gameState = await getDoc(ref);
  if (gameState.exists()) {
    const gradeLevel = Number(gameState.data()?.gradeLevel) || 1;
    const queryRef = query(
      levelsCollection,
      where("gradeLevels", "array-contains", gradeLevel)
    );
    const getLevels = await getDocs(queryRef);
    const challenges = await getGameChallenges(uid, gradeLevel);
    return getLevels.docs.map((gameLevel) => ({
      uid: gameLevel.id,
      ...gameLevel.data(),
      challenges: challenges.filter(
        (challenge: any) => challenge?.levelId === gameLevel.id
      ),
    }));
  }

  return Promise.reject(new Error("Player not found or Something went wrong!"));
};

export const getGameChallenges = async (uid: string, gradeLevel: number) => {
  const queryRef = query(
    challengesCollection,
    where("gradeLevels", "array-contains", gradeLevel)
  );
  const results = await getDocs(queryRef);
  const challenges: any = results.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  }));
  return challenges;
};

export const getChallenges = async (uid?: string) => {
  const ref = doc(gameStateCollection, uid);
  const gameState = await getDoc(ref);
  if (gameState.exists()) {
    const gradeLevel = Number(gameState.data()?.gradeLevel) || 1;
    const queryRef = query(
      challengesCollection,
      where("gradeLevels", "array-contains", gradeLevel)
    );
    const results = await getDocs(queryRef);
    return results.docs.map((doc) => ({ ...doc.data() }));
  }
  throw new Error("something is wrong");
};
