import React from "react";
import { useOutlet } from "react-router-dom";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer?: any[];
  }
}

function App() {
  const outlet = useOutlet();
  return <React.Fragment>{outlet}</React.Fragment>;
}

export default App;
