import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Button, Paper } from "@mui/material";
import { useAuth } from "../../context/AuthProvider";
import { useCallback, useEffect, useState } from "react";
import { getChildren } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/store";
import { setInfo } from "../../state/appSlice";
import { routePaths } from "../../route-paths";

const ChildSelectionPage = () => {
  const { handleLogout } = useAuth();
  const [kids, setKids] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getChildren(
      (data: any) => {
        setKids(data);
      },
      (err: any) => {
        console.log({ err });
      }
    );
  }, []);

  const handleAvatarUpdate = useCallback(
    (id: string, kid: any) => {
      const {
        authToken,
        gender,
        gradeLevel,
        name,
        parent,
        playerId,
        premium,
        uid,
      } = kid;
      dispatch(
        setInfo({
          authToken,
          gender,
          gradeLevel,
          name,
          parent,
          playerId,
          premium,
          uid,
        })
      );
      navigate(routePaths.dashboard.gamePath(`${id}`));
    },
    [dispatch, navigate]
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        backgroundImage: `url(/assets/images/bg.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "20px",
          minWidth: "500px",
          maxWidth: "800px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography
            color="secondary"
            style={{ fontSize: "1.4rem", fontWeight: "bold" }}
          >
            Who's playing?
          </Typography>
          <Button variant="outlined" color="error" onClick={handleLogout}>
            Log Out
          </Button>
        </Box>
        <Box
          sx={{
            maxHeight: "500px",
            overflow: "auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {kids?.map((kid) => (
            <Button
              key={kid?.uid}
              variant="outlined"
              color="secondary"
              className="child"
              onClick={() => handleAvatarUpdate(kid?.playerId, kid)}
            >
              <Avatar
                src={`/assets/images/${
                  kid?.gender === "male" ? "mega" : "moola"
                }-head.png`}
                className="child-image"
              />
              <Typography className="child-text">{kid?.name}</Typography>
            </Button>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};

export default ChildSelectionPage;
