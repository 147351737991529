import { useCallback, useEffect } from "react";
import { getLoggedInUserNull } from "../../services/local";
import { appStateSelector, setGameState, setInfo } from "../../state/appSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { getGameState } from "../../services/state";
import { Await, useLoaderData } from "react-router-dom";
import React from "react";
import GameWrapper from "./GameWrapper";
import { getPlayerInfo, getStudentInfo } from "../../services/user";

const GameScene = () => {
  const dispatch = useAppDispatch();
  const { data: kid } = useAppSelector(appStateSelector);
  const data = useLoaderData();

  const handleGetPlayerInfo = useCallback(async () => {
    const player_id = (data as any)?.paramId;
    const loggedInUser = getLoggedInUserNull();
    if (player_id && !loggedInUser) {
      const info = await getPlayerInfo(kid?.uid!);
      dispatch(
        setInfo({
          authToken: info?.authToken,
          gender: info?.gender,
          gradeLevel: info?.gradeLevel,
          name: info?.name,
          parent: info?.parent,
          playerId: info?.playerId,
          premium: info?.premium,
          country: info?.country,
          promoCode: info?.promoCode || "",
          uid: info?.uid,
        })
      );
      return;
    }
    if (player_id && loggedInUser) {
      const info = await getStudentInfo(loggedInUser.uid!);
      dispatch(
        setInfo({
          gradeLevel: info?.gradeLevel,
          username: loggedInUser.username,
          playerId: loggedInUser?.playerId,
          premium: info?.premium,
          promoCode: info?.promoCode || "",
          uid: loggedInUser.uid,
        })
      );
      return;
    }
  }, [data, dispatch, kid?.uid]);

  useEffect(() => {
    // if (user?.playerId) {
    handleGetPlayerInfo();
    // }
  }, [handleGetPlayerInfo]);

  useEffect(() => {
    if ((data as any)?.paramId) {
      const sub = getGameState((data as any)?.paramId, (res: any, err: any) => {
        if (!res) return;
        if (err) return;
        const { gender, coins, gradeLevel, position, premium, ...rest } = res;
        dispatch(
          setGameState({
            gender,
            coins,
            gradeLevel,
            position,
            premium,
            challengesCompleted: rest?.challengesCompleted || [],
          })
        );
        return;
      });
      return () => {
        sub();
      };
    }
  }, [data, dispatch]);

  return (
    <React.Suspense fallback={<>Loading Data...</>}>
      <Await
        resolve={(data as any)?.challenges}
        errorElement={<p>Error loading data!</p>}
      >
        {(challenges) => (
          <GameWrapper
            // user={user}
            gameLevels={(data as any)?.gameLevels}
            challenges={challenges}
          />
        )}
      </Await>
    </React.Suspense>
  );
};

export default GameScene;
