import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BackButtonLink from "../../components/ui/BackButtonLink";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import ButtonWithLoader from "../../components/ui/ButtonWithLoader";
import { Formik } from "formik";
import { useAuth } from "../../context/AuthProvider";

const StudentLoginPage = () => {
  const { handleUsernameLogin } = useAuth();
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
        <BackButtonLink path="/" />
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "16px",
            display: "block",
            textAlign: "center",
            maxWidth: "80%",
            margin: "0 auto",
            // flexGrow: 1,
          }}
        >
          Enter the Student ID provided by your school teacher
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, padding: "20px" }}>
        <Formik
          initialValues={{
            username: "",
          }}
          onSubmit={handleUsernameLogin}
        >
          {({
            values,
            isValid,
            dirty,
            isSubmitting,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form className="form" onSubmit={handleSubmit}>
              <div>
                <InputLabel htmlFor="email" className="label">
                  Student ID
                </InputLabel>
                <FormControl className="formControl">
                  <TextField
                    fullWidth
                    id="username"
                    name="username"
                    placeholder="Enter your student Id..."
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormControl>
              </div>
              <div>
                <ButtonWithLoader
                  disabled={!(isValid && dirty) || isSubmitting}
                  isLoading={isSubmitting}
                  title="Continue"
                  variant="contained"
                />
              </div>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default StudentLoginPage;
