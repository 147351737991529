import {
  collection,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase.config";

const gameStateCollection = collection(db, "gameState");
const childDataStoreCollection = collection(db, "ChildDataStore");

export const getChildData = async (studentId: string) => {
  const docRef = doc(childDataStoreCollection, studentId!);
  const result = await getDoc(docRef);
  return {
    storageId: result.data()?.storageId,
    data: result.data(),
  };
};

export const setChildData = async (studentId: string, data: any) => {
  const docRef = doc(childDataStoreCollection, studentId!);
  await setDoc(docRef, {
    storageId: studentId,
    ...data,
  });
};

export const updatePosition = async (
  playerId: string,
  data: Position
): Promise<Position> => {
  console.log("updatePosition Firebase function data", data);
  const docRef = doc(gameStateCollection, playerId);
  await updateDoc(docRef, { position: data, updatedAt: serverTimestamp() });
  return data;
};

export const updateCoins = async (playerId: string, coin: number) => {
  const docRef = doc(gameStateCollection, playerId);
  const result = await getDoc(docRef);
  if (!result.exists()) {
    return null;
  }
  await updateDoc(docRef, {
    coins: increment(coin),
    updatedAt: serverTimestamp(),
  });
  return await getCoins(playerId);
};

export const getCoins = async (playerId: string): Promise<number> => {
  const docRef = doc(gameStateCollection, playerId);
  const result = await getDoc(docRef);
  const coins = result.data()?.coins;
  return coins;
};
