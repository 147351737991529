import {
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase.config";

const challengeResultsCollection = collection(db, "challengeResults");

export const getChallengeResults = async (
  playerId: string
): Promise<ChallengeResult[]> => {
  const queryRef = query(
    challengeResultsCollection,
    where("playerId", "==", playerId)
  );
  const results = await getDocs(queryRef);
  const challengesResults: ChallengeResult[] = results.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return challengesResults;
};

export const addChallengeResults = async (
  playerId: string,
  data: ChallengeResult
): Promise<ChallengeResult> => {
  const challengeResult: ChallengeResult = {
    ...data,
    playerId,
    createdAt: serverTimestamp(),
  };
  const response = await addDoc(challengeResultsCollection, challengeResult);
  return { ...challengeResult, id: response.id };
};

export const updateChallengeResults = async (
  playerId: string,
  id: string,
  data: ChallengeResult
): Promise<ChallengeResult> => {
  const challengeResult: ChallengeResult = {
    id,
    ...data,
    playerId,
    updatedAt: serverTimestamp(),
  };
  const docRef = doc(challengeResultsCollection, id);
  await updateDoc(docRef, { ...challengeResult });
  return challengeResult;
};
