export const routePaths = {
  dashboard: {
    path: "/dashboard",
    childPath: (param: string) => `/dashboard/${param}`,
    gamePath: (param: string) => `/dashboard/${param}/game`,
  },
  auth: {
    path: "/",
    childPath: (param: string) => `/${param}`,
  },
} as const;
