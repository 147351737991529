import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import theme from "../lib/theme";
import { Link, Typography } from "@mui/material";
import { useAuth } from "../context/AuthProvider";
import { routePaths } from "../route-paths";

const AuthLayout = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (auth)
    return (
      <Navigate
        to={{
          pathname: location.state?.from?.pathname || routePaths.dashboard.path,
        }}
        replace
      />
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(/assets/images/bg.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "block",
          minWidth: "640px",
          maxWidth: "640px",
          margin: "0 auto",
          padding: "10px 0",
        }}
      >
        <Link
          href="https://teachers.moneyprep.com"
          target="_blank"
          referrerPolicy="origin"
          color="secondary"
          style={{
            display: "block",
            textAlign: "right",
            width: "100%",
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          Are you a Teacher?
        </Link>
      </Box>
      <Box
        sx={{
          minWidth: "600px",
          maxWidth: "640px",
          minHeight: "420px",
          height: "420px",
          position: "relative",
          background: "hsl(0 0% 100%)",
          boxShadow: theme.shadows[5],
        }}
      >
        <Grid container sx={{ height: "100%", marginTop: "0px" }}>
          <Grid item xs={4} sx={{ paddingTop: "0px !important" }}>
            <Box
              sx={{
                background: "hsl(219,82%,22%)",
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                height: "100%",
                paddingTop: "3rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  maxWidth: "50%",
                  margin: "0 auto",
                  flexGrow: 1,
                }}
              >
                Welcome back!
              </Typography>
              <img
                src="/assets/images/characters.svg"
                alt="mega-moola"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box sx={{ padding: "20px", height: "100%" }}>
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AuthLayout;
