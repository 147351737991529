import React from "react";
import { RouterProvider } from "react-router-dom";
import useAppRoutes from "./routes";

function Route() {
  const router = useAppRoutes();
  return <RouterProvider router={router} />;
}

export default Route;
