import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

export function getUrlPrefix() {
  switch (process.env.REACT_APP_ENV) {
    case "development":
    case "staging":
    case "production":
    default:
      return "us-central1-moneyprep-dev";
  }
}

const config = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
});

const app = getApps().length ? getApp() : initializeApp(config);

export const auth = getAuth(app);
export const fb = getFunctions(app);
export const db = getFirestore(app);
