import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

type LinkButtonProps = {
  path: string;
};
const BackButtonLink = (props: LinkButtonProps) => {
  return (
    <Link to={props.path}>
      <ArrowBack />
    </Link>
  );
};

export default BackButtonLink;
