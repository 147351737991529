import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BackButtonLink from "../../components/ui/BackButtonLink";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import ButtonWithLoader from "../../components/ui/ButtonWithLoader";
import ButtonWithIcon from "../../components/ui/ButtonWithIcon";
import { Divider, Modal } from "@mui/material";
import { Formik } from "formik";
import { useAuth } from "../../context/AuthProvider";
import { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ParentLoginPage = () => {
  const { handleEmailLogin, handleLoginWithGoogle, handleLoginWithApple } =
    useAuth();
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = (email: string) => {
    setOpen(true);
    setEmail(email);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <BackButtonLink path="/" />
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              display: "block",
              textAlign: "center",
              maxWidth: "80%",
              margin: "0 auto",
              // flexGrow: 1,
            }}
          >
            Parent login to play game
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, padding: "0 20px" }}>
          <div
            style={{
              gap: 6,
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ButtonWithIcon
              title="Continue with Google"
              variant="outlined"
              color="info"
              imageUrl="/assets/images/google.png"
              onClick={() => handleLoginWithGoogle(handleOpen)}
            />
            <ButtonWithIcon
              title="Continue with Apple"
              variant="outlined"
              color="info"
              imageUrl="/assets/images/apple.png"
              onClick={() => handleLoginWithApple(handleOpen)}
            />
          </div>
          <Divider />
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => handleEmailLogin(values, handleOpen)}
          >
            {({
              values,
              isSubmitting,
              isValid,
              dirty,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form className="form" onSubmit={handleSubmit}>
                <div>
                  <InputLabel htmlFor="email" className="label">
                    Email
                  </InputLabel>
                  <FormControl className="formControl">
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Enter your email address..."
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                </div>
                <div>
                  <InputLabel htmlFor="password" className="label">
                    Password
                  </InputLabel>
                  <FormControl className="formControl">
                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormControl>
                </div>
                <div>
                  <ButtonWithLoader
                    disabled={!(isValid && dirty) || isSubmitting}
                    isLoading={isSubmitting}
                    title="Continue"
                    variant="contained"
                  />
                </div>
              </form>
            )}
          </Formik>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Oops...🫣
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The email address <strong>{email}</strong> you tried to login with
            is not an active account. Please Sign up below in the Parent
            Dashboard
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
          <ButtonWithIcon
            title="Continue to Parent Dashboard"
            variant="outlined"
            color="info"
            imageUrl=""
            onClick={() =>
              window.open("https://parents.moneyprep.com/signup", "_blank")
            }
          />
        </Box>
      </Modal>
    </>
  );
};

export default ParentLoginPage;
