import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  redirect,
} from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import MainAuthPage from "./pages/auth/Main";
import StudentLoginPage from "./pages/auth/StudentLogin";
import ParentLoginPage from "./pages/auth/ParentLogin";
import AvatarSelectionPage from "./pages/onboarding/AvatarSelection";
import ChildSelectionPage from "./pages/onboarding/ChildSelection";
import App from "./App";
import AppLayout from "./layouts/AppLayout";
import { useMemo } from "react";
import { useAuth } from "./context/AuthProvider";
import { getGender } from "./services/user";
import GameScene from "./pages/game/Scene";
import { getChallenges, getGameLevelsResults } from "./services/state";
import { routePaths } from "./route-paths";
import NotFound from "./pages/error/NotFound";

const useAppRoutes = () => {
  const { auth } = useAuth();

  const routing = useMemo(() => {
    switch (auth?.role) {
      case "parent":
        return <Route index path="" element={<ChildSelectionPage />} />;
      default:
        return (
          <Route
            index
            path=""
            loader={async () => {
              // This is just placeholder playerId. The app works with it there.
              // When I can get a playerId in the object it will be replaced on line 37
              const gender = await getGender(auth?.playerId);
              if (!gender) return null;
              return gender;
            }}
            element={<AvatarSelectionPage />}
          />
        );
    }
  }, [auth]);

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<App />}>
        <Route path={routePaths.auth.path} element={<AuthLayout />}>
          <Route index element={<MainAuthPage />} />
          <Route path="student-login" element={<StudentLoginPage />} />
          <Route path="parent-login" element={<ParentLoginPage />} />
        </Route>
        <Route path={routePaths.dashboard.path} element={<AppLayout />}>
          {auth && routing}
          <Route
            path={":id/game"}
            loader={async ({ params }) => {
              try {
                const gameLevels = await getGameLevelsResults(params?.id!);
                const challenges = getChallenges(params?.id!);
                return defer({ gameLevels, challenges, paramId: params?.id });
              } catch (err) {
                return redirect(routePaths.dashboard.path);
              }
            }}
            element={<GameScene />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return routes;
};

export default useAppRoutes;
