import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface ButtonProps {
  title: string;
  variant: "text" | "contained" | "outlined";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  disabled?: boolean;
  isLoading?: boolean;
}
const ButtonWithLoader = (props: ButtonProps) => {
  return (
    <Button
      type="submit"
      variant={props.variant}
      color={props?.color}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
      }}
      disabled={props?.disabled || props?.isLoading}
    >
      {props?.isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "center",
          }}
        >
          <CircularProgress size={15} />
          <Typography style={{ fontSize: "14px" }}>{props.title}</Typography>
        </div>
      ) : (
        <Typography style={{ fontSize: "14px" }}>{props.title}</Typography>
      )}
    </Button>
  );
};

export default ButtonWithLoader;
