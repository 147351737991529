import { Avatar } from "@mui/material";
import Button, {ButtonProps as MuiButtonProps} from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface ButtonProps extends MuiButtonProps{
  title: string;
  // onClick: () => void;
  // variant: "text" | "contained" | "outlined";
  imageUrl: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}
const ButtonWithIcon = ({imageUrl, ...props}: ButtonProps) => {
  return (
    <Button
      // variant={props.variant}
      color={props?.color}
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
      }}
      {...props}
    >
      <Avatar src={imageUrl} style={{ width: "15px", height: "15px" }} />
      <Typography style={{ fontWeight: "600", fontSize: "15px" }}>
        {props.title}
      </Typography>
    </Button>  
  );
};

export default ButtonWithIcon;
