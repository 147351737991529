import { User } from "firebase/auth";
import { auth, fb } from "../firebase.config";
import { httpsCallable } from "firebase/functions";

const authStorage = window.sessionStorage;
const SESSION_AUTH_KEY = "localAuth";

export interface LocalAuth {
  uid?: string;
  playerId: string;
  username?: string;
}

interface FirebaseUser extends User {
  playerId: string;
}

export const getAuth = (): LocalAuth | undefined => {
  const auth = authStorage.getItem(SESSION_AUTH_KEY);
  return auth ? JSON.parse(auth) : undefined;
};

export const setAuth = (auth: LocalAuth) => {
  if (auth && auth.playerId) {
    authStorage.setItem(SESSION_AUTH_KEY, JSON.stringify(auth));
  }
};

export const deleteAuth = () => {
  authStorage.clear();
};

export const getLoggedInUser = (): FirebaseUser | LocalAuth => {
  const { currentUser } = auth;
  const localAuth = getAuth();

  if (currentUser && localAuth) {
    return { ...currentUser, playerId: localAuth.playerId } as FirebaseUser;
  }
  if (localAuth) {
    return localAuth;
  }
  if (currentUser) {
    return { ...currentUser } as FirebaseUser;
  }

  throw new Error("This user is not logged in.");
};

export const getLoggedInUserNull = (): LocalAuth | null => {
  // const { currentUser } = auth;
  const localAuth = getAuth();
  if (localAuth) {
    return localAuth;
  }

  return null;
};

export const localAuthenticateUser = async (
  username: string
): Promise<LocalAuth> => {
  const response = await httpsCallable(
    fb,
    "loginWithGeneratedId"
  )({
    id: username,
  });
  if (response.data && Object.keys(response.data).length > 0) {
    const authData = {
      username,
      uid: username,
      ...(response.data as LocalAuth),
    };
    setAuth(authData);
    return authData;
  }
  throw new Error("This user is not logged in");
};
