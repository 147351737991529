import { useEffect, useState } from "react";

const useResize = () => {
  const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio);

  useEffect(() => {
    console.log("RESIZING!!!");
    const updateDevicePixelRatio = function () {
      setPixelRatio(window.devicePixelRatio);
    };

    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${pixelRatio}dppx)`
    );

    mediaMatcher.addEventListener("change", updateDevicePixelRatio);

    return () => {
      mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
    };
  }, [pixelRatio]);

  return pixelRatio;
};

export default useResize;
