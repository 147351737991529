function NotFound() {
  return (
    <div className="nf-wrapper">
      <h1 className="nf-title">404</h1>
      <p className="nf-subtitle">Page not found</p>
    </div>
  );
}

export default NotFound;
