import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#f69f0f",
      contrastText: "#000",
    },
    secondary: {
      main: "#0a2a66",
      contrastText: "#fff",
    },
    info: {
      main: "#0a2a66",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.9375rem",
          textTransform: "none",
          fontWeight: "bold",
        },
        contained: {
          fontSize: "0.9375rem",
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
