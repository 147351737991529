import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { routePaths } from "../route-paths";

const AppLayout = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth)
    return (
      <Navigate to={routePaths.auth.path} replace state={{ from: location }} />
    );

  return <Outlet />;
};

export default AppLayout;
