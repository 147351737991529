import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: AppState = {
  data: null,
  gameState: null,
  showGenericVideo: null,
  showVideo: null,
  currentChallenge: null,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInfo(state: AppState, action) {
      state.data = action.payload;
    },
    setGameState(state: AppState, action) {
      state.gameState = action.payload;
    },
    setShowGenericVideo(state: AppState, action) {
      state.showGenericVideo = action.payload;
    },
    setShowVideo(state: AppState, action) {
      state.showVideo = action.payload;
    },
    setCurrentChallenge(state: AppState, action) {
      state.currentChallenge = action.payload;
    },
    setDataLayerPush(state: AppState, action) {
      let windowsDataLayer = window?.dataLayer || [];
      windowsDataLayer.push({
        ...action.payload,
      });
      window.dataLayer = windowsDataLayer;
    },
    clearInfo(state: AppState) {
      state.data = null;
    },
  },
  extraReducers: () => {},
});

export const {
  setInfo,
  setGameState,
  setShowGenericVideo,
  setShowVideo,
  setCurrentChallenge,
  setDataLayerPush,
  clearInfo,
} = app.actions;

export const appStateSelector = ({
  transient: {
    app: { data, gameState, showGenericVideo, showVideo, currentChallenge },
  },
}: RootState) => {
  return {
    data,
    gameState,
    showGenericVideo,
    showVideo,
    currentChallenge,
  };
};

export const appReducer = app.reducer;
