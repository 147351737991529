import {
  onAuthStateChanged as AuthStateChanged,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  sendPasswordResetEmail,
  type User,
  getAdditionalUserInfo,
  AdditionalUserInfo,
} from "firebase/auth";
import { auth } from "../firebase.config";

/**
 * State change observer
 */
export const onAuthStateChanged = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successFn: (data: User) => void,
  failureFn: () => void
) => {
  return AuthStateChanged(auth, (user) => {
    return user ? successFn(user) : failureFn();
  });
};

/**
 * Sign in to moneyprep email and password credential
 */
export const loginWithEmailAndPassword = (email: string, password: string) =>
  signInWithEmailAndPassword(auth, email, password)
    .then((res) => JSON.stringify(res))
    .then((res) => JSON.parse(res))
    .then(verifyRole)
    .then(({ token, role, uid }) => {
      return {
        token,
        role,
        uid,
      };
    })
    .catch((err) => {
      console.log("ERROR - sign in with email and password: ", err);
      switch (err.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
        case "auth/wrong-password":
        default:
          throw err;
      }
    });

// /**
//  * Sign in with google
//  */
export const loginWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");

    const result = await signInWithPopup(auth, provider);
    const userInfo = getAdditionalUserInfo(result);

    // create new user
    const { isNewUser } = userInfo as AdditionalUserInfo;

    if (isNewUser) {
      // This will ensure the authentication account is not created
      if (userInfo?.profile) {
        await result.user.delete();
      }
      throw new Error("auth/user-not-found");
    }

    const { role, token, uid: userId } = await verifyRole(null);

    // console.log("auth service: ", role, token, uid);
    return {
      role,
      token,
      uid: userId,
    };
  } catch (err) {
    console.log("Signin err", err);
    switch ((err as any).code) {
      case "auth/invalid-email":
      case "auth/user-disabled":
      case "auth/user-not-found":
      default:
        throw err;
    }
  }
};

// /**
//  * Sign in with google
//  */
export const loginWithApple = async () => {
  try {
    const provider = new OAuthProvider("apple.com");
    provider.addScope("profile");

    const result = await signInWithPopup(auth, provider);
    const userInfo = getAdditionalUserInfo(result);

    const { isNewUser } = userInfo as AdditionalUserInfo;
    if (isNewUser) {
      // This will ensure the authentication account is not created
      if (userInfo?.profile) {
        await result.user.delete();
      }
      throw new Error("auth/user-not-found");
    }

    const { role, token, uid } = await verifyRole(null);
    return {
      role,
      token,
      uid,
    };
  } catch (err) {
    console.log("Signin err", err);
    switch ((err as any).code) {
      case "auth/invalid-email":
      case "auth/user-disabled":
      case "auth/user-not-found":
      default:
        throw err;
    }
  }
};

/**
 * Forgot password
 */
export const forgotPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

/**
 * Verify role
 */
export const verifyRole = async (user?: User | null) => {
  const { currentUser } = auth || user;

  if (currentUser) {
    const auth = await currentUser
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        const { token, claims, signInProvider } = idTokenResult;
        if (claims?.role === "teacher")
          return Promise.reject(
            new Error("Only parents and students can sign in.")
          );
        // console.log("auth::verifyRole -> claims: ", claims);
        // console.log("auth::verifyRole idTokenResult: ", idTokenResult);
        if (!claims?.role) {
          // console.log("claims: ", claims);
          return Promise.reject({
            email: claims?.email,
            message: "Only parents and students can sign in.",
          });
        }
        return {
          token,
          signInProvider,
          role: claims.role,
          email: claims.email,
          uid: claims.user_id,
        };
      })
      .catch((err) => {
        console.log("verifyRole error", err);
        throw err;
      });

    return auth;
  }

  return Promise.reject(new Error("You are not signed in."));
};

/**
 * Sign out
 */
export const logout = () => auth.signOut();
