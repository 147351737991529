import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Button, CircularProgress, Grid } from "@mui/material";
import { Navigate, useLoaderData, useNavigate } from "react-router-dom";
import { getLoggedInUser } from "../../services/local";
import { useCallback, useState } from "react";
import { updateGender } from "../../services/user";
import { routePaths } from "../../route-paths";

const AvatarSelectionPage = () => {
  const [loading, setLoading] = useState(false);
  const user = getLoggedInUser();
  const gender = useLoaderData();
  const navigate = useNavigate();

  const handleAvatarUpdate = useCallback(
    async (gender: "male" | "female", playerId: string) => {
      setLoading(true);
      try {
        await updateGender(gender);
        return navigate(routePaths.dashboard.gamePath(`${playerId}`));
      } catch (error: any) {
        throw new Error(error?.message);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  if (gender)
    return (
      <Navigate
        to={routePaths.dashboard.gamePath(`${user?.playerId!}`)}
        replace
      />
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
        backgroundImage: `url(/assets/images/bg.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Typography
        color="secondary"
        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
      >
        Choose a character
      </Typography>
      <Box>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              className="selection"
              disabled={loading}
              onClick={() => handleAvatarUpdate("female", user?.playerId)}
            >
              <Typography className="selection-text">Moola</Typography>
              <Avatar
                src="/assets/images/moola.svg"
                className="selection-image"
              />
              {loading && (
                <CircularProgress
                  color="primary"
                  size={60}
                  className="selection-progress"
                />
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="info"
              className="selection"
              disabled={loading}
              onClick={() => handleAvatarUpdate("male", user?.playerId)}
            >
              <Typography className="selection-text">Mega</Typography>
              <Avatar
                src="/assets/images/mega.svg"
                className="selection-image"
              />
              {loading && (
                <CircularProgress
                  color="primary"
                  size={60}
                  className="selection-progress"
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AvatarSelectionPage;
