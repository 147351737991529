import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import {
  loginWithApple,
  loginWithEmailAndPassword,
  loginWithGoogle,
  verifyRole,
} from "../services/auth";
import { logout, onAuthStateChanged } from "../services/auth";
import {
  LocalAuth,
  deleteAuth,
  getAuth,
  localAuthenticateUser,
} from "../services/local";

interface User extends LocalAuth {
  token: string;
  signInProvider: string | null;
  role: any;
  email: any;
  uid: any;
}

interface EmailLogin {
  email: string;
  password: string;
}
interface UsernameLogin {
  username: string;
}

interface AuthContextProp {
  auth: User;
  handleLogout(): void;
  handleEmailLogin: (
    payload: EmailLogin,
    handleOpenCallback: (email: string) => void
  ) => void;
  handleUsernameLogin: (payload: UsernameLogin) => void;
  handleLoginWithGoogle: (handleOpenCallback: (email: string) => void) => void;
  handleLoginWithApple: (handleOpenCallback: (email: string) => void) => void;
}

const AuthContext = createContext<AuthContextProp | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useContext must be called within its context");
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}
const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, setAuth] = useState<any | null>(null);
  const mounted = useRef(false);

  const handleLogout = useCallback(async () => {
    setAuth(null);
    deleteAuth();
    await logout();
  }, []);

  const handleEmailLogin = useCallback(
    async (
      { email, password }: EmailLogin,
      handleOpenCb: (email: string) => void
    ) => {
      try {
        const auth = await loginWithEmailAndPassword(email, password);
        setAuth(auth);
        return auth;
      } catch (error) {
        console.log({ error });
        handleOpenCb(email);
      }
    },
    []
  );

  const handleLoginWithGoogle = useCallback(
    async (handleOpenCb: (email: string) => void) => {
      try {
        const auth = await loginWithGoogle();
        setAuth(auth);
        return auth;
      } catch (error) {
        console.log({ error });
        const { email } = error as any;
        handleOpenCb(email);
      }
    },
    []
  );
  const handleLoginWithApple = useCallback(
    async (handleOpenCb: (email: string) => void) => {
      try {
        const auth = await loginWithApple();
        setAuth(auth);
      } catch (error) {
        console.log("AuthProvider loginWithApple error => ", { error });
        const { email } = error as any;
        handleOpenCb(email);
      }
    },
    []
  );
  const handleUsernameLogin = useCallback(
    async ({ username }: UsernameLogin) => {
      try {
        const auth = await localAuthenticateUser(username);
        setAuth(auth);
        return auth;
      } catch (error) {
        console.log({ error });
      }
    },
    []
  );

  useEffect(() => {
    if (mounted.current === false) {
      const unsubscribe = onAuthStateChanged(
        async (user) => {
          const data = await verifyRole();
          return setAuth(data);
        },
        async () => {
          const res = getAuth();
          if (!res) handleLogout();
          return setAuth(res);
        }
      );
      return () => {
        unsubscribe();
        mounted.current = true;
      };
    }
  }, [handleLogout]);

  const value = useMemo(
    () => ({
      auth,
      handleLogout,
      handleEmailLogin,
      handleUsernameLogin,
      handleLoginWithGoogle,
      handleLoginWithApple,
    }),
    [
      auth,
      handleLogout,
      handleEmailLogin,
      handleUsernameLogin,
      handleLoginWithGoogle,
      handleLoginWithApple,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
