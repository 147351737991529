import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { routePaths } from "../../route-paths";

const MainAuthPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        height: "100%",
      }}
    >
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "18px",
          display: "block",
          textAlign: "center",
          margin: "18px 0",
        }}
      >
        Choose how to enter the game
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
          gap: "5px",
        }}
      >
        <Grid item>
          <LinkButton
            path={routePaths.auth.childPath("student-login")}
            title="Login with Student ID"
            imageUrl="/assets/images/school.svg"
          />
        </Grid>
        <Grid item>
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: "16px",
              textAlign: "center",
              padding: "0 16px",
            }}
          >
            OR
          </Typography>
        </Grid>
        <Grid item>
          <LinkButton
            path={routePaths.auth.childPath("parent-login")}
            title="Login with Home Account"
            imageUrl="/assets/images/home.svg"
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default MainAuthPage;

type LinkButtonProps = {
  path: string;
  title: string;
  imageUrl: string;
};
const LinkButton = (props: LinkButtonProps) => {
  return (
    <Link to={props.path}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Avatar src={props.imageUrl} sx={{ width: "100px", height: "100px" }} />
        <Typography
          sx={{
            display: "block",
            textAlign: "center",
            color: "black",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          {props.title}
        </Typography>
      </Box>
    </Link>
  );
};
