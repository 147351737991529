import { auth, db } from "../firebase.config";
import {
  query,
  where,
  collection,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getLoggedInUser } from "./local";

const playerCollection = collection(db, "players");
const studentCollection = collection(db, "studentIds");
const gameStateCollection = collection(db, "gameState");
const timestamp = serverTimestamp();

export const getChildren = (onSuccess: any, onError: any) => {
  const { currentUser } = auth;

  if (currentUser) {
    const { uid } = currentUser;
    const queryData = query(playerCollection, where("parent", "==", uid));

    return onSnapshot(
      queryData,
      (snapshot) => {
        if (!snapshot) return onSuccess([]);

        const children = snapshot.docs.map((child) => child.data());

        return onSuccess(children);
      },
      (err) => {
        console.log(err);
        return onError(new Error(err.message));
      }
    );
  }

  return Promise.reject(new Error("You are not signed in."));
};

export const getPlayerInfo = async (uid: string) => {
  const { currentUser } = auth;

  if (currentUser) {
    const docRef = doc(playerCollection, uid!);
    const docData = await getDoc(docRef);
    return docData.data();
  }

  return Promise.reject(new Error("You are not signed in."));
};

export const getStudentInfo = async (username: string) => {
  const loggedInUser = getLoggedInUser();

  if (loggedInUser.playerId) {
    const docRef = doc(studentCollection, username!);
    const docData = await getDoc(docRef);
    return docData.data();
  }

  return Promise.reject(new Error("You are not signed in."));
};

// export const updateUser = (data: any) => {
//   const loggedInUser = getLoggedInUser();

//   const docRef = playerCollection.doc(loggedInUser.playerId);
//   const { role, ...rest } = data;

//   return docRef.update({ ...rest, updatedAt: timestamp });
// };

export const updateGender = async (
  gender: "male" | "female"
): Promise<void> => {
  const loggedInUser = getLoggedInUser();
  const docRef = doc(gameStateCollection, loggedInUser.playerId);
  return await updateDoc(docRef, { gender, updatedAt: timestamp });
};

export const getGender = async (uid: string) => {
  const docRef = doc(gameStateCollection, uid!);
  const docData = await getDoc(docRef);
  return docData.data()?.gender;
};
