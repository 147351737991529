import Button from "@mui/material/Button";
import { Fragment, useCallback, useEffect, useMemo } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ModalVideo from "react-modal-video";
import { useAuth } from "../../context/AuthProvider";
import useResize from "../../hooks/useResize";
import {
  getChildData,
  setChildData,
  updateCoins,
  updatePosition,
} from "../../services/game";
import { ReactUnityEventParameter } from "react-unity-webgl/distribution/types/react-unity-event-parameters";
import {
  appStateSelector,
  clearInfo,
  setCurrentChallenge,
  setDataLayerPush,
  setShowGenericVideo,
  setShowVideo,
} from "../../state/appSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { addChallengeResults } from "../../services/challenges";
import { Navigate, useLocation } from "react-router-dom";
import { routePaths } from "../../route-paths";

const { REACT_APP_UNITY_GAME_BUILD_URL } = process.env;

const loaderUrl = `${REACT_APP_UNITY_GAME_BUILD_URL}/moneyprepunity/Build/build.loader.js`;
const dataUrl = `${REACT_APP_UNITY_GAME_BUILD_URL}/moneyprepunity/Build/build.data`;
const frameworkUrl = `${REACT_APP_UNITY_GAME_BUILD_URL}/moneyprepunity/Build/build.framework.js`;
const codeUrl = `${REACT_APP_UNITY_GAME_BUILD_URL}/moneyprepunity/Build/build.wasm`;
const streamingAssetsUrl = `${REACT_APP_UNITY_GAME_BUILD_URL}/moneyprepunity/StreamingAssets`;

// To test build locally, reach out to Ali to provide this doc to download to local machine.
// const loaderUrl = `/assets/build/Build/build.loader.js`;
// const dataUrl = `/assets/build/Build/build.data`;
// const frameworkUrl = `/assets/build/Build/build.framework.js`;
// const codeUrl = `/assets/build/Build/build.wasm`;
// const streamingAssetsUrl = `/assets/build/StreamingAssets`;

const GameWrapper = (props: GameWrapperProps) => {
  // console.log(props?.user);
  const location = useLocation();
  const { handleLogout } = useAuth();
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
    sendMessage,
    unload,
  } = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl,
    streamingAssetsUrl,
    productName: "MoneyPrep Game",
    productVersion: "4.3",
    companyName: "MoneyPrep",
  });
  const framePixelRatio = useResize();
  const loadingPercentage = Math.round(loadingProgression * 100);

  const dispatch = useAppDispatch();
  const {
    showGenericVideo,
    showVideo,
    currentChallenge,
    gameState: gameStateObj,
    data: playerInfo,
  } = useAppSelector(appStateSelector);

  const gameState = useMemo(
    () => ({
      ...gameStateObj,
      premium: playerInfo?.premium || gameStateObj?.premium,
      gameChallengesCompleted: gameStateObj?.challengesCompleted || [],
    }),
    [gameStateObj, playerInfo?.premium]
  );

  const getCompletedChallenges = useMemo(() => {
    const { gameChallengesCompleted } = gameState;
    return props?.challenges.map((challenge) => {
      const getLevelId =
        props?.gameLevels?.find((level) => level.uid === challenge.levelId)
          ?.levelId || 1;
      const isComplete: boolean = gameChallengesCompleted
        ? !!gameChallengesCompleted[
            `${getLevelId}-${challenge.challengeId}` as any
          ]
        : false;
      return {
        challengeId: challenge.challengeId,
        levelId: getLevelId,
        isComplete,
      };
    });
  }, [gameState, props?.challenges, props?.gameLevels]);

  const userData = useMemo(
    () => ({
      authToken: playerInfo?.authToken || null,
      studentId: playerInfo?.username || playerInfo?.uid || "",
      playerId: playerInfo?.playerId,
      country: playerInfo?.country || null,
      uid: playerInfo?.uid || null,
      promoCode: playerInfo?.promoCode || "",
      premium: playerInfo?.premium || false,
      promoEndDate: null,
      paymentId: null,
      paymentIds: [],
      store: [],
      gameState: { ...gameState, challengesCompleted: [] } || {},
      challengesCompleted: getCompletedChallenges,
      gameLevels: props?.gameLevels || [],
      storeItemEquipableItems: [],
      storeItemConsumeableItems: [],
      fromMobileApp: false,
    }),
    [
      gameState,
      getCompletedChallenges,
      playerInfo?.authToken,
      playerInfo?.country,
      playerInfo?.playerId,
      playerInfo?.premium,
      playerInfo?.promoCode,
      playerInfo?.uid,
      playerInfo?.username,
      props?.gameLevels,
    ]
  );

  const handleSendUserData = useCallback(() => {
    console.log("handleUserData", { userData });
    sendMessage("GameDataManager", "loginFromReact", JSON.stringify(userData));
  }, [sendMessage, userData]);

  const handleUpdateCoin = useCallback(
    (coins: ReactUnityEventParameter) => {
      updateCoins(userData.playerId, Number(coins))
        .then((value) => {
          sendMessage("GameManager", "UpdatePiggyBank", Number(value));
        })
        .catch((err) => console.error(err));
    },
    [sendMessage, userData.playerId]
  );

  const handleOpenGenericVideo = useCallback(
    (videoId: ReactUnityEventParameter) => {
      dispatch(setShowGenericVideo(videoId));
    },
    [dispatch]
  );

  const handleCloseGenericVideo = useCallback(() => {
    if (!!showGenericVideo) {
      sendMessage("GameManager", "GenericVideoClosed");
      dispatch(setShowGenericVideo(null));
    }
  }, [dispatch, sendMessage, showGenericVideo]);

  const handleUnloadAndLogOut = async () => {
    await unload();
    handleLogout();
  };

  const handleUnload = useCallback(async () => {
    dispatch(clearInfo());
    await unload();
  }, [dispatch, unload]);

  const handleUpdatePlayerPosition = useCallback(
    (position: ReactUnityEventParameter) => {
      const { levelId, sceneId } = JSON.parse(String(position));
      updatePosition(userData.playerId, { levelId, sceneId })
        .then(() => console.log("Position Updated"))
        .catch((err) => console.error(err));
    },
    [userData.playerId]
  );

  const handleUpdateChallengeData = useCallback(
    (data: ReactUnityEventParameter) => {
      const result = JSON.parse(String(data));
      if (result.attempSuccessful || result.attemptSuccessful) {
        addChallengeResults(userData.playerId, {
          challengeId: result.challengeId,
          levelId: result.levelId,
          coinsEarned: 1,
        })
          .then(() => console.log("Challenge Updated!!"))
          .catch((err) => console.error(err));
      }
    },
    [userData.playerId]
  );

  const handleGetStorageData = useCallback(() => {
    getChildData(userData.studentId)
      .then((result) => {
        console.log("GOT Child Data!");
        sendMessage(
          "VirtualWorldDataManager",
          "playerDataFromServer",
          JSON.stringify(result)
        );
      })
      .catch((err) => console.error(err));
  }, [sendMessage, userData.studentId]);

  const handleSetStorageData = useCallback(
    (data: ReactUnityEventParameter) => {
      setChildData(userData.studentId, JSON.parse(data?.toString()!))
        .then(() => console.log("Added Child Data"))
        .catch((err) => console.error(err));
    },
    [userData.studentId]
  );

  const handleGameplayEvent = useCallback(() => {
    console.log("unityGamePlayEvent");
  }, []);

  const handleOpenVideoChallenge = useCallback(
    (num: ReactUnityEventParameter) => {
      const currentLevel = props?.gameLevels?.find(
        (level) =>
          !level.premium && level.levelId === gameState?.position?.levelId
      );
      const videoChallenge = props?.challenges?.find(
        (challenge) =>
          challenge.challengeId === Number(num) &&
          currentLevel?.uid === challenge.levelId
      );

      if (videoChallenge && videoChallenge.type) {
        if (videoChallenge.type === "video") {
          const { premium, uid, studentId } = userData;
          dispatch(setCurrentChallenge(Number(num)));
          dispatch(setShowVideo(videoChallenge.videoId));
          dispatch(
            setDataLayerPush({
              event: "challenges_started",
              uuid: uid,
              grade_level: gameState?.gradeLevel,
              is_school_account: studentId ? true : false,
              is_premium_user: premium,
              studentId: studentId,
            })
          );
        }
      }
    },
    [
      props?.gameLevels,
      props?.challenges,
      gameState?.position?.levelId,
      gameState?.gradeLevel,
      userData,
      dispatch,
    ]
  );

  const handleVideoClose = useCallback(() => {
    if (!!showVideo && gameState?.position) {
      addChallengeResults(userData?.playerId, {
        challengeId: gameState.position.sceneId,
        levelId: gameState.position.levelId,
        coinsEarned: 1,
      })
        .then(() => {
          console.log("Updated Challenge on video close");
          sendMessage(
            "GameManager",
            "ChallengeCompleted",
            currentChallenge as ReactUnityEventParameter
          );

          const { premium, uid, studentId } = userData;

          dispatch(
            setDataLayerPush({
              event: "challenges_won",
              uuid: uid,
              grade_level: gameState?.gradeLevel,
              is_school_account: studentId ? true : false,
              is_premium_user: premium,
              studentId: studentId,
            })
          );

          dispatch(setShowVideo(null));
        })
        .catch((err) => console.error(err));
    }
  }, [
    showVideo,
    gameState.position,
    gameState?.gradeLevel,
    userData,
    sendMessage,
    currentChallenge,
    dispatch,
  ]);

  // UNITY IS LOADED
  useEffect(() => {
    addEventListener("UnityIsLoaded", handleSendUserData);
    addEventListener("addCoin", handleUpdateCoin);
    addEventListener("OpenGenericVideo", handleOpenGenericVideo);
    addEventListener("UpdateSceneAndChallengePos", handleUpdatePlayerPosition);
    addEventListener("UpdateChallengeData", handleUpdateChallengeData);
    addEventListener("GetStorageData", handleGetStorageData);
    addEventListener("SaveStorageData", handleSetStorageData);
    addEventListener("unityGamePlayEvent", handleGameplayEvent);
    addEventListener("OpenVideo", handleOpenVideoChallenge);
    return () => {
      removeEventListener("UnityIsLoaded", handleSendUserData);
      removeEventListener("addCoin", handleUpdateCoin);
      removeEventListener("OpenGenericVideo", handleOpenGenericVideo);
      removeEventListener(
        "UpdateSceneAndChallengePos",
        handleUpdatePlayerPosition
      );
      removeEventListener("UpdateChallengeData", handleUpdateChallengeData);
      removeEventListener("GetStorageData", handleGetStorageData);
      removeEventListener("SaveStorageData", handleSetStorageData);
      removeEventListener("unityGamePlayEvent", handleGameplayEvent);
      removeEventListener("OpenVideo", handleOpenVideoChallenge);
    };
  }, [
    addEventListener,
    handleGameplayEvent,
    handleGetStorageData,
    handleOpenGenericVideo,
    handleOpenVideoChallenge,
    handleSendUserData,
    handleSetStorageData,
    handleUpdateChallengeData,
    handleUpdateCoin,
    handleUpdatePlayerPosition,
    removeEventListener,
  ]);

  useEffect(() => {
    if (isLoaded) {
      return () => {
        handleUnload();
      };
    }
  }, [handleUnload, isLoaded]);

  if (!playerInfo && !props.gameLevels)
    return (
      <Navigate
        to={{
          pathname: location.state?.from?.pathname || routePaths.dashboard.path,
        }}
        replace
      />
    );

  return (
    <Fragment>
      <div className="container">
        <div className="game-header">
          <Button
            variant="contained"
            color="error"
            onClick={handleUnloadAndLogOut}
          >
            Log Out
          </Button>
        </div>
        {isLoaded === false && (
          // We'll conditionally render the loading overlay if the Unity
          // Application is not loaded.
          <div className="loading-overlay">
            <p>Loading... ({loadingPercentage}%)</p>
          </div>
        )}
        <Unity
          className="unity"
          style={{ visibility: isLoaded ? "visible" : "hidden" }}
          unityProvider={unityProvider}
          devicePixelRatio={framePixelRatio}
        />
      </div>
      {showGenericVideo && (
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 1 }}
          isOpen={!!showGenericVideo}
          videoId={showGenericVideo}
          onClose={handleCloseGenericVideo}
        />
      )}
      {showVideo && (
        <ModalVideo
          channel="youtube"
          youtube={{ mute: 0, autoplay: 1 }}
          isOpen={!!showVideo}
          videoId={showVideo}
          onClose={handleVideoClose}
        />
      )}
    </Fragment>
  );
};

export default GameWrapper;
